import Link from 'next/link';

const HomeLink = () => (
  <Link id='home-link' href='/'>
    <a>
      <img src='/logos/atm-nha-colored.svg' alt='ATM Nhà' className='h-[40px] w-[132px]' />
    </a>
  </Link>
);

export default HomeLink;
